import React from "react";
import unauthorized from "../assets/unauthorized.svg";
import { Box, Typography, Button } from "@mui/material";

export default function UnauthorizedAccess() {
  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <Box>
      <div>
        <img src={unauthorized} />
      </div>
      <Typography mb={2} mt={-3} style={{ fontSize: "21px" }}>
        Oops! You don't seem to have access to this page.
      </Typography>
      <Button
        className="primary-button-filled"
        variant="contained"
        onClick={handleGoBack}
        sx={{
          padding: "12px 28px",
          borderRadius: "26px !important"
        }}
      >
        Go Back
      </Button>
    </Box>
  );
}
