import CustomDateTimePicker from "./DateTimePicker";
import "./date-time-filter.css";
export default function DateTimeFilter({
  startTime,
  endTime,
  setStartTime,
  setEndTime
}) {
  const handleStartTimeChange = (val) => {
    setStartTime(val);
  };

  const handleEndTimeChange = (val) => {
    setEndTime(val);
  };

  return (
    <div className="date-time-filter">
      <div>Filter by date</div>

      <div className="input-container">
        <CustomDateTimePicker
          label="From"
          time={startTime}
          onChangeHandler={handleStartTimeChange}
          maxDateTime={endTime}
        />
        <CustomDateTimePicker
          label="To"
          time={endTime}
          onChangeHandler={handleEndTimeChange}
          minDateTime={startTime}
        />
      </div>
    </div>
  );
}