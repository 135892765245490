import { properties } from "../properties";
import api from "../services/api";


function getAllWithPagination(pageSize, pageNumber, searchText) {
  return api.get(
    `${properties.adminService}/ad-user/adaptive-configuration?searchText=${searchText}&pageSize=${pageSize}&pageNumber=${pageNumber}`
  );
}

function addAdaptiveConfiguration(data) {
  return api.post(
    `${properties.adminService}/ad-user/adaptive-configuration`,
    data
  );
}

function getAdaptiveConfiguration(id) {
  return api.get(`${properties.adminService}/ad-user/adaptive-configuration/${id}`);
}

function updateAdaptiveConfiguration(id,values) {
  return api.put(
    `${properties.adminService}/ad-user/adaptive-configuration/${id}`,
    values
  );
}

function deleteAdaptivePolicy(id) {
  return api.delete(
    `${properties.adminService}/ad-user/adaptive-configuration/${id}`
  );
}

function updateIsEnabled(id, isEnabled) {
  return api.patch(
    `${properties.adminService}/ad-user/adaptive-configuration/${id}/is-enabled`,
    { isEnabled: isEnabled }
  );
}


export {
  addAdaptiveConfiguration,
  getAdaptiveConfiguration,
  updateAdaptiveConfiguration,
  getAllWithPagination,
  deleteAdaptivePolicy,
  updateIsEnabled
};
