import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  TextField,
  MenuItem,
  Box,
  FormControl,
  Select,
  Toolbar,
  IconButton,
  Divider,
  InputLabel
} from "@mui/material";
import CustomTooltip from "../../utils/Tooltip";
import {
  addSessionConfiguration,
  getSessionConfiguration,
  updateSessionConfiguration
} from "../../API/ad_user_policy";
import StyledSwitch from "../utility/StyledSwitch";
import FormShimmering from "../utility/FormShimmer";
import CloseIcon from "@mui/icons-material/Close";
import CheckboxSelectorListWithSearch from "../utility/CheckBoxSelectorListWithSearch";
import { fetchAndFormatUserGroups } from "../../utils/Helpers";
import FormFooter from "../utility/FormFooter";

const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  concurrentSessions: Yup.number()
    .min(1, "Must be at least 1")
    .max(3, "Must be less than 4")
    .required("Required"),
  actionOnExceed: Yup.string().required("Required"),
  inactivityPeriod: Yup.number()
    .min(1, "Must be at least 1 minute")
    .required("Required"),
  notifyAdmin: Yup.boolean(),
  notifyEndUser: Yup.boolean(),
  userGroupIds: Yup.array().min(1, "Please select at least one group.")
});

const SessionPolicyForm = ({
  open,
  setOpen,
  selectedId,
  setFeedback,
  setFetchCount
}) => {
  const formType = selectedId ? "Update" : "Add";
  const [loading, setLoading] = useState(false);

  const Action = {
    TERMINATE_ALL: "TERMINATE_ALL",
    DENY_CURRENT: "DENY_CURRENT",
    TERMINATE_OLDEST: "TERMINATE_OLDEST"
  };

  const handleSubmit = (values) => {
    let promise;
    if (formType == "Add") {
      promise = addSessionConfiguration(values);
    } else {
      promise = updateSessionConfiguration(selectedId, values);
    }

    promise
      .then(() => {
        setFeedback({
          severity: "success",
          message: "Successfully saved!",
          state: true
        });
        setOpen(false);
        setFetchCount((prev) => prev + 1);
      })
      .catch(() => {
        setFeedback({
          severity: "error",
          message: "There is an issue while saving configuration!",
          state: true
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      id: selectedId,
      name: "",
      isEnabled: true,
      concurrentSessions: "",
      actionOnExceed: Action.DENY_CURRENT,
      inactivityPeriod: "",
      notifyAdmin: false,
      notifyEndUser: false,
      userGroupIds: []
    },
    validationSchema,
    onSubmit: handleSubmit
  });

  useEffect(() => {
    formik.resetForm();
    if (open && formType == "Update") {
      setLoading(true);
      getSessionConfiguration(selectedId)
        .then((response) => {
          if (response.data) formik.setValues(response.data);
        })
        .catch(() => {
          setFeedback({
            severity: "error",
            message: "There is an issue while fetching configuration!",
            state: true
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      className={`drawer-form-wrapper ${
        open ? "drawer-form-open" : "drawer-form-closed"
      }`}
      style={{ width: "60%" }}
    >
      <Toolbar />
      <br />
      <div className="close-button">
        <IconButton onClick={handleClose} sx={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </div>
      {loading ? (
        <FormShimmering />
      ) : (
        <div className="drawer-form-container">
          <h5>Session Policy</h5>
          <Divider />

          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} columns={24}>
              <Grid item md={13}>
                <div className="form-input">
                  <InputLabel
                    htmlFor="name"
                    className="form-input-label"
                    required
                  >
                    Name
                  </InputLabel>
                  <TextField
                    size="small"
                    id="name"
                    placeholder="Name"
                    className="form-textfield"
                    fullWidth
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </div>
                <div className="form-input">
                  <InputLabel
                    htmlFor="concurrentSessions"
                    className="form-input-label"
                    required
                  >
                    Number of Concurrent Sessions
                    <CustomTooltip title="Set the maximum number of concurrent sessions allowed." />
                  </InputLabel>

                  <TextField
                    type="number"
                    fullWidth
                    name="concurrentSessions"
                    size="small"
                    value={formik.values.concurrentSessions}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.concurrentSessions &&
                      Boolean(formik.errors.concurrentSessions)
                    }
                    helperText={
                      formik.touched.concurrentSessions &&
                      formik.errors.concurrentSessions
                    }
                  />
                </div>
                <div className="form-input">
                  <InputLabel
                    htmlFor="actionOnExceed"
                    className="form-input-label"
                    required
                  >
                    Action on Exceed
                    <CustomTooltip title="Choose the action when concurrent session limit is exceeded." />
                  </InputLabel>
                  <FormControl fullWidth>
                    <Select
                      name="actionOnExceed"
                      size="small"
                      value={formik.values.actionOnExceed}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.actionOnExceed &&
                        Boolean(formik.errors.actionOnExceed)
                      }
                    >
                      <MenuItem value={Action.TERMINATE_ALL}>
                        Terminate All Previous Sessions
                      </MenuItem>
                      <MenuItem value={Action.TERMINATE_OLDEST}>
                        Terminate Oldest Session
                      </MenuItem>
                      <MenuItem value={Action.DENY_CURRENT}>
                        Deny Access to Current Session
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="form-input">
                  <InputLabel
                    htmlFor="inactivityPeriod"
                    className="form-input-label"
                    required
                  >
                    Period of Inactivity to Terminate Session
                    <CustomTooltip title="Specify the inactivity duration (in minutes) after which a session is terminated." />
                  </InputLabel>
                  <div style={{ display: "flex", alignSelf: "center" }}>
                    <TextField
                      size="small"
                      type="number"
                      fullWidth
                      name="inactivityPeriod"
                      value={formik.values.inactivityPeriod}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.inactivityPeriod &&
                        Boolean(formik.errors.inactivityPeriod)
                      }
                      helperText={
                        formik.touched.inactivityPeriod &&
                        formik.errors.inactivityPeriod
                      }
                    />
                    <span style={{ paddingLeft: "12px" }}>minutes</span>
                  </div>
                </div>
                <div className="form-input">
                  <InputLabel
                    htmlFor="notifyAdmin"
                    className="form-input-label"
                  >
                    Notify on Session Exceed
                    <CustomTooltip title="Enable this option to notify the admin when session limits are exceeded." />
                  </InputLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="notifyAdmin"
                        checked={formik.values.notifyAdmin}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Notify Admin"
                  />
                </div>
                {/* <div className="form-input">
                  <FormControlLabel
                    control={
                      <StyledSwitch
                        name="isEnabled"
                        checked={formik.values.isEnabled}
                        onChange={formik.handleChange}
                        sx={{ marginRight: "20px" }}
                      />
                    }
                    sx={{ marginLeft: "0px" }}
                    label ="Enable Sesion Restriction"
                  />
                </div> */}
              </Grid>
              <Grid md={1} item sx={{ Height: "100%" }}>
                <Divider orientation="vertical" sx={{ opacity: 1 }} />
              </Grid>
              <Grid item md={10}>
                <br />
                {formik.touched.userGroupIds && formik.errors.userGroupIds && (
                  <div className="error-text">{formik.errors.userGroupIds}</div>
                )}
                <CheckboxSelectorListWithSearch
                  heading="Select User Groups"
                  setFeedback={setFeedback}
                  fetchAndFormatFields={fetchAndFormatUserGroups}
                  selectedRecords={formik.values.userGroupIds}
                  setSelectedRecords={(values) =>
                    formik.setFieldValue("userGroupIds", values)
                  }
                  options={{
                    showFilters: true,
                    showSearchBar: true
                  }}
                  maxHeight="300px"
                  showAddRecordButton={true}
                  addButtonUrl={"/user-groups"}
                  addButtonText={"Create Group"}
                  isRequired={true}
                />
              </Grid>
            </Grid>
            <FormFooter
              setOpen={setOpen}
              formType={formType}
              handleSubmit={formik.handleSubmit}
              handleClose={handleClose}
            />
          </form>
        </div>
      )}
    </div>
  );
};

export default SessionPolicyForm;
