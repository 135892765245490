import { properties } from "../properties";
import api from "../services/api";

function getAllWithFilter(pageSize, pageNumber, searchText) {
  return api.get(
    `${properties.adminService}/session/ad-user/configuration?searchText=${searchText}&pageSize=${pageSize}&pageNumber=${pageNumber}`
  );
}

function addSessionConfiguration(values) {
  return api.post(
    `${properties.adminService}/session/ad-user/configuration`,
    values
  );
}

function getSessionConfiguration(id) {
  return api.get(
    `${properties.adminService}/session/ad-user/configuration/${id}`
  );
}

function updateSessionConfiguration(id, values) {
  return api.put(
    `${properties.adminService}/session/ad-user/configuration/${id}`,
    values
  );
}

function getAllActiveSession(pageSize, pageNumber, searchText) {
  return api.get(
    `${properties.adminService}/session/ad-user?searchText=${searchText}&pageSize=${pageSize}&pageNumber=${pageNumber}`
  );
}

function deleteSessionPolicy(id) {
  return api.delete(
    `${properties.adminService}/session/ad-user/configuration/${id}`
  );
}

function updateIsEnabled(id, isEnabled) {
  return api.patch(
    `${properties.adminService}/session/ad-user/configuration/${id}/is-enabled`,
    { isEnabled: isEnabled }
  );
}

export {
  getSessionConfiguration,
  addSessionConfiguration,
  updateSessionConfiguration,
  getAllActiveSession,
  getAllWithFilter,
  deleteSessionPolicy,
  updateIsEnabled
};
