import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

export default function CustomDateTimePicker({ label, time, onChangeHandler, minDateTime, maxDateTime }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label={label}
        value={time ? dayjs(time) : null}
        onChange={onChangeHandler}
        minDateTime={minDateTime ? dayjs(minDateTime) : undefined}
        maxDateTime={maxDateTime ? dayjs(maxDateTime) : undefined}
        className="date-time-picker"
        slotProps={{
          actionBar: {
            actions: ["clear", "accept"]
          }
        }}
      />
    </LocalizationProvider>
  );
}
