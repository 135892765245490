import { useEffect, useState } from "react";
import TableComponent from "../utility/Table";
import Pagination from "../utility/Pagination";
import "../reporting/device-reports.css";
import PageTitle from "../utility/PageTitle";
import { formatDateTimeString } from "../../utils/Helpers";
import { Box, Button } from "@mui/material";
import DateTimeFilter from "../utility/DateTimeFilter";
import "./reporting-dashboard.css";
import { downloadSAPReport, getSAPReports } from "../../API/sapReporting";
import { Download } from "@mui/icons-material";
import AutoRefresh from "../utility/AutoRefresh";
import CustomAlert from "../../Infobar";

export default function SAPReportList({ setFeedback }) {
  const [data, setData] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [autoRefreshCount, setAutoRefreshCount] = useState(0);

  const subheading = "Monitor devices who tried to use SAP";

  const getReportingList = () => {
    getSAPReports(rowsPerPage, page, searchText, startTime, endTime)
      .then(({ data }) => {
        const processedData = data.reportList.map((item) => {
          return {
            ...item,
            incidentDttm: formatDateTimeString(item.incidentDttm)
          };
        });
        setTotalCount(data.totalItems);
        setData(processedData);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: error,
          state: true
        });
      });
  };

  useEffect(() => {
    getReportingList();
    setAutoRefreshCount((autoRefreshCount) => (autoRefreshCount += 1));
  }, [pageNumber, searchText, rowsPerPage, page, startTime, endTime]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (windowWidth > 1536) {
      setRowsPerPage(100);
    } else {
      setRowsPerPage(10);
    }
  }, [windowWidth]);

  const handleDownload = () => {
    downloadSAPReport(searchText, startTime, endTime)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "report.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(() => {
        setFeedback({
          severity: "error",
          message: "There is an issue while downloading report.",
          state: true
        });
      });
  };

  const headings = [
    "reportId",
    "deviceName",
    "incidentDttm",
    "sharedUsername",
    "sapUsername",
    "miniorangeUsername",
    "transaction",
    "clientNumber",
    "currentSapProgram",
    "status"
  ];

  return (
    <div>
      <Box className="d-flex justify-content-between align-items-center">
        <PageTitle subheading={subheading} />
        <AutoRefresh
          getListHandler={getReportingList}
          autoRefreshCount={autoRefreshCount}
        />
      </Box>
      {totalCount === 0 && (
        <CustomAlert
          type="info"
          text={
            <>
              Note: To enable the SAP reporting module, please contact us at{" "}
              <a href="mailto:dlpsupport@xecurify.com" target="_blank">
                dlpsupport@xecurify.com
              </a>
              .
            </>
          }
          styles={{ marginTop: "15px" }}
        />
      )}
      <div className="Filter">
        <DateTimeFilter
          startTime={startTime}
          endTime={endTime}
          setStartTime={setStartTime}
          setEndTime={setEndTime}
        />
        <Button
          startIcon={<Download />}
          variant="contained"
          className="primary-button-filled"
          sx={{ mt: 0, mx: 1, height: "fit-content" }}
          onClick={handleDownload}
          disabled={totalCount == 0}
        >
          Download
        </Button>
      </div>
      <TableComponent
        headings={headings}
        data={data}
        styles={{
          table: { maxHeight: windowWidth > 1536 ? "auto" : 480 }
        }}
        customColumnStyles={{
          deviceName: { fontWeight: 500 }
        }}
        customColumnNames={{
          reportId: "ID",
          incidentDttm: "Incident Time"
        }}
        options={{ hideIds: true }}
      />
      {totalCount != 0 ? (
        <div className="pagination">
          <Pagination
            totalCount={totalCount}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </div>
      ) : null}
    </div>
  );
}
