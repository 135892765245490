import { LOGIN_REQUEST, SUCCESS, FAILURE, LOGOUT_REQUEST } from "./authTypes";
import CookieService from "../../CookieService";
import { properties } from "../../../properties";
import api from "../../api";
import { getDashboardUser } from "../../../API/dashboardUser";
import { getSubDomain } from "../../../utils/Helpers";
import { fetchPrivilege } from "../../../API/manageRoles";
import { ADMIN_PRIVILEGE } from "../../../utils/Privileges";
import { ROLE_ID_MAP } from "../../../utils/Constants";

const accessTokenExpiresAt = 8 * 60 * 60 * 1000;
const refreshTokenExpiresAt = 6 * 24 * 60 * 60 * 1000;

const calculateExpiryDate = (expiresInMillis) => {
  const expiryDate = new Date();
  expiryDate.setTime(expiryDate.getTime() + expiresInMillis);
  return expiryDate;
};

const createTokenOptions = () => {
  return { path: "/" };
};

export const authenticateUser = (response) => async (dispatch) => {
  try {
    CookieService.set(
      "access_token",
      response.data.token,
      createTokenOptions()
    );
    CookieService.set(
      "refresh_token",
      response.data.refreshToken,
      createTokenOptions()
    );
    const userResponse = await getDashboardUser();
    const currentUser = userResponse.data;
    const privilegeMap = {};
    if (currentUser?.roleId) {
      const privilegeResponse = await fetchPrivilege(currentUser.roleId);
      const privileges = privilegeResponse.data;

      privileges.forEach((element) => {
        privilegeMap[element.name] = true;
      });

      if (currentUser.roleId === ROLE_ID_MAP.ADMIN) {
        privilegeMap[ADMIN_PRIVILEGE] = true;
      }
    }
    dispatch(dispatchCurrentUser(currentUser, true, privilegeMap));
    return response.data;
  } catch (error) {
    dispatch(failure());
    return Promise.reject(error);
  }
};

export const isUserLoggedIn = () => async (dispatch) => {
  try {
    // Get the refresh token
    const token = CookieService.get("refresh_token");

    // If no token, mark the user as logged out
    if (!token) {
      dispatch(dispatchCurrentUser());
      return false;
    }

    // Fetch the current user if token exists
    const response = await getDashboardUser();
    const currentUser = response.data;
    const privilegeMap = {};

    // If user data is valid, fetch privileges based on roleId
    if (currentUser?.roleId) {
      const privilegeResponse = await fetchPrivilege(currentUser.roleId);
      const privileges = privilegeResponse.data;

      privileges.forEach((element) => {
        privilegeMap[element.name] = true;
      });

      if (currentUser.roleId === ROLE_ID_MAP.ADMIN) {
        privilegeMap[ADMIN_PRIVILEGE] = true;
      }
      // Dispatch user data and privileges
      dispatch(dispatchCurrentUser(currentUser, true, privilegeMap));
      return true;
    }

    // If no user data, treat as not logged in
    dispatch(dispatchCurrentUser());
    return false;
  } catch (error) {
    dispatch(dispatchCurrentUser());
    return false;
  }
};

export const updateToken = () => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const prevRefreshToken = CookieService.get("refresh_token");
    if (!prevRefreshToken) {
      dispatch(logoutUser());
      reject();
    }
    api
      .post(`${properties.gateway}/auth/refreshtoken`, {
        refreshToken: prevRefreshToken
      })
      .then((response) => {
        const { accessToken, refreshToken } = response.data;
        CookieService.set(
          "access_token",
          accessToken,
          createTokenOptions()
        );
        CookieService.set(
          "refresh_token",
          refreshToken,
          createTokenOptions()
        );
        resolve();
      })
      .catch((error) => {
        dispatch(logoutUser());
        reject(error);
      });
  });
};

export const logoutUser = () => async (dispatch) => {
  api
    .post(`${properties.gateway}/auth/signout`, {
      refreshToken: CookieService.get("refresh_token")
    })
    .finally(() => {
      CookieService.removeAll();
      dispatch(logoutRequest());
    });
};

export const loginRequest = () => {
  return {
    type: LOGIN_REQUEST
  };
};

const logoutRequest = () => {
  return {
    type: LOGOUT_REQUEST
  };
};

const failure = () => {
  return {
    type: FAILURE,
    payload: false
  };
};

const dispatchCurrentUser = (
  currentUser = {},
  isLoggedIn = false,
  privileges = {}
) => {
  return {
    type: "SET_CURRENT_USER",
    payload: { currentUser, isLoggedIn, privileges }
  };
};

export const tokenRefreshingStatus = (status) => {
  return {
    type: "SET_REFRESH_STATUS",
    payload: status
  };
};

export const setAutoRefresh = (refreshStatus) => {
  return {
    type: "SET_AUTO_REFRESH",
    payload: refreshStatus
  };
};
