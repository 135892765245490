import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import { authenticateUser } from "../../services/index";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Logo from "../assets/logo.svg";
import Button from "@mui/material/Button";
import { getSubDomain } from "../../utils/Helpers";
import { MFA_STATUS } from "../../utils/Constants";
import { authenticate, validateOtp } from "../../API/login";
import MfaPrompt from "./MfaPrompt";

function LoginPage({ setFeedback }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [showMfaPrompt, setShowMfaPrompt] = useState(false);

  const validateUser = ({ email, password }) => {
    authenticate(email, password)
      .then((response) => {
        if (response.data === MFA_STATUS.TOTP_REQUIRED) {
          setShowMfaPrompt(true);
          return; 
        } else {
          dispatch(authenticateUser(response)).then(() => {
            const redirectTo = location.state?.redirectTo?.pathname || "/dashboard";
            setFeedback({
              severity: "success",
              message: "Logged in Successfully",
              state: true
            });
            navigate(redirectTo, { replace: true });
          });
        }
      })
      .catch((error) => {
        setFeedback({
          message: error.response.data,
          severity: "error",
          state: true
        });
      });
  };
  
  
  const validate = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string().required("Password is required")
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: validate,
    onSubmit: (values, { resetForm }) => {
      validateUser(values);
    }
  });

  const [showPassword, setShowPassword] = useState(false);

  const submitOtp = (otp) => {
    validateOtp(formik.values.email, formik.values.password, otp)
      .then((response) => {
        if (response.data.totpVerificationStatus === "FAILURE") {
          setFeedback({
            message: response.data.totpVerificationMessage,
            severity: "error",
            state: true
          });
        } else {
          dispatch(authenticateUser(response)).then(() => {
            navigate("/dashboard", {
              state: { message: "Logged in Successfully" }
            });
          });
        }
      })
      .catch((error) => {
        setFeedback({
          message: error.response.data,
          severity: "error",
          state: true
        });
      });
  };
  return (
    <>
      <div className="auth-form">
        <img className="logo" src={Logo} />
        <br />
        <br />
        <div style={{ display: showMfaPrompt ? "none" : "block" }}>
          <h1 className="auth-heading">Welcome Back!</h1>

          <p className="auth-subheading">
            Enter your credentials to access your DLP Dashboard
          </p>
          <br />
          <form className="form" onSubmit={formik.handleSubmit}>
            <TextField
              autoComplete="email"
              fullWidth
              required
              className="input-field"
              placeholder="johndoe@company.com"
              name="email"
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PermIdentityIcon />
                  </InputAdornment>
                ),
                style: {
                  fontSize: "16px"
                }
              }}
              InputLabelProps={{
                style: { color: "#848484", fontSize: "20px" }
              }}
            />
            <br />
            <TextField
              autoComplete="on"
              className="input-field"
              fullWidth
              required
              placeholder="*******"
              name="password"
              type={showPassword ? "text" : "password"}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon sx={{ height: "24px", width: "24px" }} />
                  </InputAdornment>
                ),
                style: {
                  fontSize: "20px"
                }
              }}
              InputLabelProps={{
                style: { color: "#848484", fontSize: "15px" }
              }}
            />
            <br />
            <Button
              className="submit-button"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              LOGIN
            </Button>
            {getSubDomain() ? null : (
              <Button
                className="submit-button"
                type="button"
                fullWidth
                variant="outlined"
                color="primary"
                style={{ marginTop: 9, backgroundColor: "transparent" }}
                onClick={() => navigate("/signup")}
              >
                SIGN UP
              </Button>
            )}
          </form>
          <div
            style={{
              alignItems: "flex-start",
              paddingTop: 3
            }}
            className="form"
          >
            <Link to={"/forgot-password"}>Forgot Password</Link>
          </div>
        </div>
        {showMfaPrompt && <MfaPrompt onComplete={submitOtp} />}
      </div>
    </>
  );
}
export default LoginPage;
