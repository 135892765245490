import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  TextField,
  MenuItem,
  Button,
  Box,
  InputLabel,
  Select,
  Grid,
  Chip,
  IconButton,
  Toolbar,
  Divider,
  Tabs,
  Tab
} from "@mui/material";
import {
  AccessTime,
  Add,
  Delete,
  MoreTime,
  Close as CloseIcon
} from "@mui/icons-material";
import { TIME_ZONE_OPTIONS, WEEKDAYS } from "../../utils/Constants";
import {
  addAdaptiveConfiguration,
  updateAdaptiveConfiguration,
  getAdaptiveConfiguration
} from "../../API/ad_user_adaptive_configuration";
import FormShimmering from "../utility/FormShimmer";
import CheckboxSelectorListWithSearch from "../utility/CheckBoxSelectorListWithSearch";
import { fetchAndFormatUserGroups } from "../../utils/Helpers";
import FormFooter from "../utility/FormFooter";
import CustomTabPanel from "../other/CustomTabPanel";

const AdaptivePolicyForm = ({
  open,
  setOpen,
  selectedId,
  setFeedback,
  setFetchCount
}) => {
  const formType = selectedId ? "Update" : "Add";
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const Action = {
    NONE: "NONE",
    ALLOW: "ALLOW",
    DENY: "DENY"
  };

  useEffect(() => {
    formik.resetForm();
    if (open && formType == "Update") {
      setLoading(true);
      getAdaptiveConfiguration(selectedId)
        .then((response) => {
          if (response.data) formik.setValues(response.data);
        })
        .catch(() => {
          setFeedback({
            severity: "error",
            message: "There is an issue while fetching configuration!",
            state: true
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [open]);

  const formik = useFormik({
    initialValues: {
      name: "",
      isEnabled: true,
      timeRestriction: Action.NONE,
      timeEntries: [{ weekdays: [], startTime: "", endTime: "" }],
      locationRestriction: Action.NONE,
      locations: [{ city: "", radius: "" }],
      timezone: "",
      ipRestriction: Action.NONE,
      ipEntries: [{ ipVersion: "IPv4", startIp: "", endIp: "" }],
      userGroupIds: []
    },
    onSubmit: (values) => {
      handleSubmit(values);
    }
  });

  const handleSubmit = (values) => {
    let promise;
    if (formType == "Add") {
      promise = addAdaptiveConfiguration(values);
    } else {
      promise = updateAdaptiveConfiguration(selectedId, values);
    }

    promise
      .then(() => {
        setFeedback({
          severity: "success",
          message: "Successfully saved!",
          state: true
        });
        setOpen(false);
        setFetchCount((prev) => prev + 1);
      })
      .catch(() => {
        setFeedback({
          severity: "error",
          message: "There is an issue while saving configuration!",
          state: true
        });
      });
  };

  const addTimeEntry = () => {
    formik.setFieldValue("timeEntries", [
      ...formik.values.timeEntries,
      { weekdays: [], startTime: "", endTime: "" }
    ]);
  };

  const removeTimeEntry = (index) => {
    formik.setFieldValue(
      "timeEntries",
      formik.values.timeEntries.filter((_, i) => i !== index)
    );
  };

  const addLocation = () => {
    formik.setFieldValue("locations", [
      ...formik.values.locations,
      { city: "", radius: "" }
    ]);
  };

  const removeLocationEntry = (index) => {
    formik.setFieldValue(
      "locations",
      formik.values.locations.filter((_, i) => i !== index)
    );
  };

  const addIpEntry = () => {
    formik.setFieldValue("ipEntries", [
      ...formik.values.ipEntries,
      { ipVersion: "IPv4", startIp: "", endIp: "" }
    ]);
  };

  const removeIpEntry = (index) => {
    formik.setFieldValue(
      "ipEntries",
      formik.values.ipEntries.filter((_, i) => i !== index)
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div
      className={`drawer-form-wrapper ${
        open ? "drawer-form-open" : "drawer-form-closed"
      }`}
      style={{ width: "50%" }}
    >
      <Toolbar />
      <br />
      <div className="close-button">
        <IconButton onClick={handleClose} sx={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </div>
      {loading ? (
        <FormShimmering />
      ) : (
        <div className="drawer-form-container">
          <h5>Adaptive Policy</h5>
          <Divider />
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="basic tabs"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="General" />
                <Tab label="Time-Based Restriction" />
                <Tab label="IP-Based Restriction" />
                <Tab label="Associate Groups" />
              </Tabs>
            </Box>
            <Grid container spacing={2} className="group-form-container">
              <Grid item md={24}>
                <Box sx={{ width: "100%" }}>
                  <CustomTabPanel value={tabValue} index={0}>
                    <div className="helper-text">
                      This field allows the user to specify the name of the
                      Adaptive Policy.
                    </div>
                    <div className="form-input">
                      <InputLabel
                        htmlFor="name"
                        className="form-input-label"
                        required
                      >
                        Name
                      </InputLabel>
                      <TextField
                        size="small"
                        id="name"
                        placeholder="Name"
                        className="form-textfield"
                        fullWidth
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                        helperText={formik.touched.name && formik.errors.name}
                      />
                    </div>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabValue} index={1}>
                    <div className="helper-text">
                      You can control when your end users access their accounts
                      by setting the time and timezone.{" "}
                    </div>
                    <br></br>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <InputLabel sx={{ mr: 1 }}>
                            Select Restriction
                          </InputLabel>
                          <Select
                            size="small"
                            name="timeRestriction"
                            value={formik.values.timeRestriction}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.timeRestriction &&
                              Boolean(formik.errors.timeRestriction)
                            }
                          >
                            <MenuItem value={Action.NONE}>
                              No Restriction
                            </MenuItem>
                            <MenuItem value={Action.ALLOW}>
                              Allow Only Within Time
                            </MenuItem>
                            <MenuItem value={Action.DENY}>
                              Deny Within Time
                            </MenuItem>
                          </Select>
                        </Box>
                      </Grid>

                      {formik.values.timeRestriction !== Action.NONE && (
                        <Grid item xs={12}>
                          <Grid item xs={12}>
                            <InputLabel>Timezone</InputLabel>
                            <Select
                              displayEmpty
                              size="small"
                              name="timezone"
                              value={formik.values.timezone}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.timezone &&
                                Boolean(formik.errors.timezone)
                              }
                            >
                              <MenuItem value="" key="" disabled hidden>
                                <span className="placeholder-style">
                                  Select Timezone
                                </span>
                              </MenuItem>
                              {TIME_ZONE_OPTIONS.map((tz) => (
                                <MenuItem key={tz.value} value={tz.value}>
                                  {tz.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                          <br />
                          <div sx={{ maxHeight: "100px", overflowY: "auto" }}>
                            {formik.values.timeEntries.map((entry, index) => (
                              <Grid
                                container
                                spacing={1}
                                key={index}
                                sx={{ mt: 0.1 }}
                              >
                                <Grid item xs={7}>
                                  <InputLabel>Weekday</InputLabel>
                                  <Select
                                    sx={{ width: "90%" }}
                                    multiple
                                    size="small"
                                    name={`timeEntries[${index}].weekdays`}
                                    value={entry.weekdays}
                                    onChange={formik.handleChange}
                                    renderValue={(selected) => (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexWrap: "wrap",
                                          gap: 0.5
                                        }}
                                      >
                                        {selected.map((value) => (
                                          <Chip
                                            key={value}
                                            label={value}
                                            size="small"
                                          />
                                        ))}
                                      </Box>
                                    )}
                                  >
                                    {WEEKDAYS.map((day, index) => (
                                      <MenuItem key={index} value={day.value}>
                                        {day.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </Grid>
                                <Grid item xs={2}>
                                  <InputLabel>Start Time</InputLabel>
                                  <TextField
                                    size="small"
                                    name={`timeEntries[${index}].startTime`}
                                    type="time"
                                    value={entry.startTime}
                                    onChange={formik.handleChange}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <InputLabel>End Time</InputLabel>
                                  <TextField
                                    size="small"
                                    name={`timeEntries[${index}].endTime`}
                                    type="time"
                                    value={entry.endTime}
                                    onChange={formik.handleChange}
                                  />
                                </Grid>
                                <Grid item xs={1} alignContent={"center"}>
                                  <IconButton
                                    onClick={() => removeTimeEntry(index)}
                                    color="error"
                                  >
                                    <Delete />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            ))}
                          </div>

                          <Button
                            startIcon={<MoreTime />}
                            onClick={addTimeEntry}
                            sx={{ mt: 2 }}
                          >
                            Add More
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </CustomTabPanel>

                  <CustomTabPanel value={tabValue} index={2}>
                    {/* 
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div>
            <div style={{ display: "flex", gap: "20px" }}>
              <LocationOn />
              <h5>Location-Based Restriction</h5>
            </div>
            <br />
            <div className="helper-text">
              You can control from where your end users access their accounts.
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails sx={{ display: "flex" }}>
          <Grid
            container
            spacing={2}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Grid item xs={12}>
              <InputLabel>Location-Based Restriction</InputLabel>
              <Select
                size="small"
                name="locationRestriction"
                value={formik.values.locationRestriction}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.locationRestriction &&
                  Boolean(formik.errors.locationRestriction)
                }
              >
                <MenuItem value={Action.NONE}>No Restriction</MenuItem>
                <MenuItem value={Action.ALLOW}>Allow Only These Locations</MenuItem>
                <MenuItem value={Action.DENY}>Deny These Locations</MenuItem>
              </Select>
            </Grid>

            {formik.values.locationRestriction !== Action.NONE && (
              <Grid item xs={12}>
                {formik.values.locations.map((location, index) => (
                  <Grid container item spacing={2} key={index}>
                    <Grid item xs={2}>
                      <InputLabel>City</InputLabel>
                      <TextField
                        size="small"
                        value={location.city}
                        name={`locations[${index}].city`}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <InputLabel>Radius (km)</InputLabel>
                      <TextField
                        size="small"
                        type="number"
                        value={location.radius}
                        name={`locations[${index}].radius`}
                        onChange={formik.handleChange}
                      />
                    </Grid>

                    <Grid item xs={1} alignContent={"end"}>
                      <IconButton
                        onClick={() => removeLocationEntry(index)}
                        color="error"
                      >
                        <Delete />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Button
                    startIcon={<AddLocationAlt />}
                    onClick={addLocation}
                    sx={{ mt: 2 }}
                  >
                    Add More
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion> */}
                    <div className="helper-text">
                      You can control from which IP address end users access
                      their accounts.
                    </div>
                    <br></br>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <InputLabel>IP Restriction</InputLabel>
                          <Select
                            size="small"
                            name="ipRestriction"
                            value={formik.values.ipRestriction}
                            onChange={formik.handleChange}
                            sx={{ ml: 1 }}
                          >
                            <MenuItem value={Action.NONE}>
                              No Restriction
                            </MenuItem>
                            <MenuItem value={Action.ALLOW}>
                              Allow Only These IPs
                            </MenuItem>
                            <MenuItem value={Action.DENY}>
                              Deny These IPs
                            </MenuItem>
                          </Select>
                        </Box>
                      </Grid>

                      {formik.values.ipRestriction !== Action.NONE && (
                        <Grid item xs={12}>
                          {formik.values.ipEntries.map((entry, index) => (
                            <Grid
                              container
                              spacing={2}
                              key={index}
                              sx={{ mt: 0.1 }}
                            >
                              <Grid item xs={2}>
                                <InputLabel>IP Version</InputLabel>
                                <Select
                                  size="small"
                                  name={`ipEntries[${index}].ipVersion`}
                                  value={entry.ipVersion}
                                  onChange={formik.handleChange}
                                >
                                  <MenuItem value="IPv4">IPv4</MenuItem>
                                  <MenuItem value="IPv6">IPv6</MenuItem>
                                </Select>
                              </Grid>
                              <Grid item xs={4}>
                                <InputLabel>Start IP</InputLabel>
                                <TextField
                                  size="small"
                                  name={`ipEntries[${index}].startIp`}
                                  value={entry.startIp}
                                  onChange={formik.handleChange}
                                  error={
                                    formik.touched.ipEntries &&
                                    Boolean(formik.errors.ipEntries)
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <InputLabel>End IP</InputLabel>
                                <TextField
                                  size="small"
                                  name={`ipEntries[${index}].endIp`}
                                  value={entry.endIp}
                                  onChange={formik.handleChange}
                                  error={
                                    formik.touched.ipEntries &&
                                    Boolean(formik.errors.ipEntries)
                                  }
                                />
                              </Grid>
                              <Grid item xs={2} alignContent={"end"}>
                                <IconButton
                                  onClick={() => removeIpEntry(index)}
                                  color="error"
                                >
                                  <Delete />
                                </IconButton>
                              </Grid>
                            </Grid>
                          ))}
                          <Grid item xs={12}>
                            <Button
                              startIcon={<Add />}
                              onClick={addIpEntry}
                              sx={{ mt: 2 }}
                            >
                              Add More
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabValue} index={3}>
                    <div className="helper-text">
                      Select user groups to apply these restrictions.
                    </div>
                    <br></br>
                    <CheckboxSelectorListWithSearch
                      heading="Select User Groups"
                      setFeedback={setFeedback}
                      fetchAndFormatFields={fetchAndFormatUserGroups}
                      selectedRecords={formik.values.userGroupIds}
                      setSelectedRecords={(values) =>
                        formik.setFieldValue("userGroupIds", values)
                      }
                      options={{
                        showFilters: true,
                        showSearchBar: true
                      }}
                      maxHeight="300px"
                      showAddRecordButton={true}
                      addButtonUrl={"/user-groups"}
                      addButtonText={"Create Group"}
                      isRequired={true}
                    />
                  </CustomTabPanel>
                </Box>
              </Grid>
            </Grid>
            <FormFooter
              setOpen={setOpen}
              formType={formType}
              handleSubmit={formik.handleSubmit}
              handleClose={handleClose}
            />
          </form>
        </div>
      )}
    </div>
  );
};

export default AdaptivePolicyForm;
