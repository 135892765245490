import React, { useState, useEffect } from "react";
import TableComponent from "../utility/Table";
import SearchBar from "../utility/SearchBar";
import { DIALOG_TYPES } from "../../utils/Constants";
import CustomDialog from "../utility/Dialog";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import Pagination from "../utility/Pagination";
import CreateButton from "../utility/CreateButton";
import AdaptivePolicyForm from "./AdaptivePolicyForm";
import { deleteAdaptivePolicy, getAllWithPagination, updateIsEnabled } from "../../API/ad_user_adaptive_configuration";


function AdaptivePolicyList({ setFeedback }) {
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [fetchCount, setFetchCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState([]);

  useEffect(() => {
    getAllWithPagination(pageSize, pageNumber, searchText)
      .then(({ data }) => {
        setData(data.data);
        setTotalCount(data.totalCount);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "There is an issue while fetching policies!",
          state: true
        });
      });
  }, [fetchCount, pageNumber, searchText, pageSize]);

  const handleDelete = (id) => {
    setIsDeleteConfirmationOpen(true);
    setSelectedId(id);
  };

  const handleConfirmDelete = () => {
    deleteAdaptivePolicy(selectedId)
      .then((response) => {
        setFeedback({
          severity: "success",
          message: "Policy deleted successfully",
          state: true
        });
        setFetchCount((prev) => prev + 1);
      })
      .catch(function (error) {
        setFeedback({
          severity: "error",
          message: error.response.data,
          state: true
        });
      })
      .finally(() => {
        setIsDeleteConfirmationOpen(false);
        setSelectedId("");
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
    setSelectedId(null);
  };

  const handleView = (id) => {
    setOpen(true);
    setSelectedId(id);
  };

  const checkIsEnabled = (id) => {
    return data.find((item) => item.id === id).isEnabled;
  };

  const handleToggle = (id, event) => {
    const isChecked = event.target.checked;
    const message = isChecked
      ? "Policy Enabled."
      : "Policy Disabled.";

    updateIsEnabled(id, isChecked)
      .then(() => {
        setData(
          data.map((item) => {
            if (item.id === id) item.isEnabled = isChecked;
            return item;
          })
        );
        setFeedback({
          severity: "success",
          message: message,
          state: true
        });
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "Something went wrong",
          state: true
        });
      });
  };

  return (
    <div>
      <div className="main-title-heading-container">
        <SearchBar
          setSearchText={setSearchText}
          searchText={searchText}
          placeholder={"Search by Name"}
        />
        <CreateButton
          label={"Create Policy"}
          onClickHandler={handleClickOpen}
          startIcon={<PersonAddAlt1Icon />}
        />
      </div>

      <TableComponent
        headings={["id", "name", "isEnabled"]}
        customColumnNames={{
          isEnabled: "Status"
        }}
        data={data}
        options={{ hideIds: true }}
        styles={{
          isEnabled: "switch"
        }}
        actions={[
          {
            label: "Edit",
            onClickHandler: handleView,
            hasParameter: true
          },
          {
            label: "Delete",
            onClickHandler: handleDelete,
            hasParameter: true
          }
        ]}
        toggleAction={{
          toggleStatusChecker: checkIsEnabled,
          onChangeHandler: handleToggle
        }}
      />
      {totalCount != 0 ? (
        <div className="pagination">
          <Pagination
            totalCount={totalCount}
            page={pageNumber}
            setPage={setPageNumber}
            rowsPerPage={pageSize}
            setRowsPerPage={setPageSize}
          />
        </div>
      ) : null}
      <AdaptivePolicyForm
        open={open}
        setOpen={setOpen}
        setFeedback={setFeedback}
        selectedId={selectedId}
        setFetchCount={setFetchCount}
      />
      <CustomDialog
        open={isDeleteConfirmationOpen}
        title="Confirm Deletion"
        content="Are you sure you want to delete?"
        setOpen={setIsDeleteConfirmationOpen}
        handleConfirm={handleConfirmDelete}
        dialogType={DIALOG_TYPES.CONFIRM}
      />
    </div>
  );
}

export default AdaptivePolicyList;
