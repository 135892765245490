import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

function ProtectedRoute({ children }) {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const location = useLocation(); 

  return isLoggedIn ? (
    children
  ) : (
    <Navigate to="/login" state={{ redirectTo: location }} replace />
  );
}

export default ProtectedRoute;
