import "./App.css";
import React, { Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { isUserLoggedIn } from "./services/index";
import { DrawerAndNavigationBar } from "./components/other";
import {
  Signup,
  ForgotPasswordPage,
  LoginPage,
  EmailSentPage,
  ChangePassword
} from "./components/auth";
import ProtectedRoute from "./components/other/ProtectedRoute";
import { useState } from "react";
import Feedback from "./components/utility/Feedback";
import AuthContainer from "./components/auth/AuthContainer";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CircularLoader from "./components/assets/CircularLoader";
import PageNotFound from "./components/other/PageNotFound";
import { ROUTES } from "./utils/routes";
import CheckPrivilege from "./components/utility/CheckPrivilege";
import { properties } from "./properties";
import UnauthorizedAccess from "./components/other/UnauthorizedAccess";

function App() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [loading, setLoading] = useState(true);
  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: '"DM Sans", sans-serif',
        textTransform: "none",
        fontSize: "14px",
        fontWeight: "500"
      }
    },
    palette: {
      text: {
        secondary: "black"
      }
    }
  });
  const [feedback, setFeedback] = useState({
    severity: "error",
    message: "",
    state: false
  });

  useEffect(() => {
    dispatch(isUserLoggedIn()).then((result) => {
      setLoading(false);
    });
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        {loading ? (
          <CircularLoader />
        ) : (
          <Router>
            <Feedback feedback={feedback} setFeedback={setFeedback} />

            <Routes>
              <Route element={<AuthContainer />}>
                <Route
                  path="/login"
                  element={<LoginPage setFeedback={setFeedback} />}
                />
                <Route
                  path="/signup"
                  element={<Signup setFeedback={setFeedback} />}
                />
                <Route
                  path="/forgot-password"
                  element={<ForgotPasswordPage setFeedback={setFeedback} />}
                />
                <Route
                  path="/email-sent"
                  element={<EmailSentPage setFeedback={setFeedback} />}
                />
                <Route
                  path="/user/:userId/reset-password/:token"
                  element={<ChangePassword setFeedback={setFeedback} />}
                />
              </Route>
              <Route
                element={
                  <ProtectedRoute>
                    <DrawerAndNavigationBar />
                  </ProtectedRoute>
                }
              >
                {ROUTES.map(
                  ({
                    path,
                    element: Element,
                    props,
                    children,
                    requiredPrivileges
                  }) => (
                    <Route
                      key={path}
                      path={path}
                      element={
                        <CheckPrivilege
                          requiredPrivileges={requiredPrivileges}
                          fallback={<UnauthorizedAccess />}
                        >
                          <Suspense fallback={<CircularLoader />}>
                            <Element setFeedback={setFeedback} {...props} />
                          </Suspense>
                        </CheckPrivilege>
                      }
                    >
                      {children
                        ? children.map(
                            ({
                              path: childPath,
                              element: ChildElement,
                              props: childProps,
                              requiredPrivileges
                            }) => (
                              <Route
                                key={childPath}
                                path={childPath}
                                element={
                                  <CheckPrivilege
                                    requiredPrivileges={requiredPrivileges}
                                    fallback={<UnauthorizedAccess />}
                                  >
                                    <ChildElement
                                      setFeedback={setFeedback}
                                      {...childProps}
                                    />
                                  </CheckPrivilege>
                                }
                              />
                            )
                          )
                        : null}
                    </Route>
                  )
                )}
              </Route>
              <Route
              path="*"
              element={
                isLoggedIn ? (
                  <PageNotFound />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
          </Routes>
          </Router>
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
